<template>
  <div>
    <div class="img">
      <el-image
        :src="require('../../static/image/home/hotelBanner.png')"
      ></el-image>
      <div class="home1Title">
        <div class="title fwhite">玩住虎酒店系统</div>
        <div class="dec fwhite" :style="{ width: decWhite + 'px' }">
          连锁酒店，电竞酒店的生意好参谋，多样化营销系统，助力酒店创收，营销能力
          +
          工具，让营销产生营收价值，界面简洁、功能全面、实时数据、图表展示、支持多终端、跨平台、移动应用、简单易用;
        </div>
        <div class="mt30">
          <el-button type="primary" class="btn" @click="goToDownload">立即下载</el-button>
        </div>
      </div>
    </div>
    <div class="pt90 pb50 bg7FA img">
      <div class="mark1">
        <el-image
          :src="require('../../static/image/home/index-bg1.png')"
        ></el-image>
      </div>
      <div class="mark2">
        <el-image
          :src="require('../../static/image/home/index-bg2.png')"
        ></el-image>
      </div>
      <div class="mb30 align-c">
        <div class="title">性能强大、安全、稳定的酒店系统</div>
        <div class="subTitle">想客户之想，急客户所急</div>
      </div>
      <div>
        <el-row>
          <el-col :span="16" :offset="4">
            <el-row :gutter="20" class="menuBg" :style="{ 'height': decHight + 'px' }">
              <el-col :span="5">
                <el-menu
                  :default-active="activeIndex"
                  menu-trigger="hover"
                  class="el-menu-vertical-demo"
                  @select="handleOpen"
                >
                  <el-menu-item index="1">
                    <span slot="title">功能齐全，简单易用</span>
                  </el-menu-item>
                  <el-menu-item index="2">
                    <span slot="title">电脑如何防盗？</span>
                  </el-menu-item>
                  <el-menu-item index="3">
                    <span slot="title">传统酒店PMS只管住？</span>
                  </el-menu-item>
                  <el-menu-item index="4">
                    <span slot="title">智能家居是伪需求？</span>
                  </el-menu-item>
                  <el-menu-item index="5">
                    <span slot="title">活动投入大转化少？</span>
                  </el-menu-item>
                  <el-menu-item index="6">
                    <span slot="title">时常在外，店况难撑住？</span>
                  </el-menu-item>
                  <el-menu-item index="7">
                    <span slot="title">如何让商品多卖易管？</span>
                  </el-menu-item>
                  <el-menu-item index="8">
                    <span slot="title">如何留人用人？</span>
                  </el-menu-item>
                  <el-menu-item index="9">
                    <span slot="title">你真的懂你的客户吗？</span>
                  </el-menu-item>
                </el-menu>
              </el-col>
              <el-col :span="18">
                <menu-detail :activedIndex="activedIndex"></menu-detail>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="img">
      <el-image
        :src="require('../../static/image/home/hotelBg2.png')"
      ></el-image>
      <div class="home3Title">
        <div class="mb30 align-c">
          <div class="title fwhite">多样化营销系统 助力酒店创收</div>
          <div class="color2AF">任何商品、服务可变成收入</div>
        </div>
        <div class="menu1">
          <el-menu
            :default-active="activeIndex1"
            menu-trigger="hover"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleOpen1"
          >
            <el-menu-item index="1">
              <!-- margin-left: 25%; -->
              <span slot="title">最强大的移动营销工具</span>
            </el-menu-item>
            <el-menu-item index="2">
              <span slot="title">多样化市场活动</span>
            </el-menu-item>
            <el-menu-item index="3">
              <span slot="title">会员自由体系</span>
            </el-menu-item>
          </el-menu>
        </div>
        <menu-detail-three :activedIndex1="activedIndex1"></menu-detail-three>
      </div>
      
    </div>
    <div class="img">
      <el-image
        :src="require('../../static/image/home/hotelBg3.png')"
      ></el-image>
      <div class="home4Title">
        <div class="mb30 align-c">
          <div class="title">全新订房小程序</div>
          <div class="subTitle">
            全新UI界面，全面丰富的展示品牌形象，产品形象，助力加盟及销售
          </div>
        </div>
      </div>
    </div>
    <div class="img">
      <el-image
        :src="require('../../static/image/home/hotelBg4.png')"
      ></el-image>
      <div class="home4Title">
        <div class="mb30 align-c">
          <div class="title">电子房价牌模板皮肤</div>
          <div class="subTitle">
            门店电子房价牌，通过会员权益、活动展示、门店实景展示、协助前台快速拉会员。
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../components/footer.vue'
import menuDetail from '../components/menuDetail.vue'
import menuDetailThree from '../components/menuDetailThree.vue'
export default {
  components: { Footer, menuDetail, menuDetailThree },
  data() {
    return {
      screenWidth: 0,
      decWhite: 0,
      screenHight: 0,
      decHight: 0,
      activeIndex: '1',
      activedIndex: '1',
      activeIndex1: '1',
      activedIndex1: '1',
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log('handleOpen', key, keyPath, this.activeIndex)
      this.activedIndex = key
    },
    handleOpen1(key, keyPath) {
      console.log('handleOpen', key, keyPath, this.activeIndex)
      this.activedIndex1 = key
    },
    goToDownload() {
      this.$router.push({
        path: '/download',
        query:{id:4 },
        // name: 'download',
        // params:{id:4 },
      })
    },
  },
  mounted() {
    // 首次加载时，初始化宽度
    this.screenWidth = document.documentElement.clientWidth
    this.screenHight = document.documentElement.clientHeight
    this.decWhite = (490 / 1920) * this.screenWidth
    this.decHight = (830 / 1920) * this.screenWidth
    // 窗口大小发生变化
    window.onresize = () => {
      this.screenWidth = window.innerWidth
      this.screenHight = window.innerHeight
      this.decWhite = (490 / 1920) * this.screenWidth
      this.decHight = (830 / 1920) * this.screenHight
    }
    console.log('decWhite', this.decWhite, 'screenWidth', this.screenWidth, 'screenHight', this.screenHight)
    console.log('decHight', this.decHight)
  },
}
</script>
<style lang="scss" scoped>
.img {
  position: relative;

  .el-image {
    display: flex;
  }

  .home1Title {
    position: absolute;
    top: 35%;
    left: 20%;
  }
  .mark1 {
    position: absolute;
    top: 10%;
    left: 20%;
  }
  .mark2 {
    position: absolute;
    bottom: 1%;
    right: 20%;
  }

  .home3Title {
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .home4Title {
    position: absolute;
    top: 18%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.dec {
  line-height: 30px;
  font-size: 16px;
}
.btn {
  padding: 10px 42px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
}
.menuBg {
  height: 830px;
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 5px 8px 30px 1px rgba(48, 49, 51, 0.06);
}
::v-deep .el-menu {
  // height: 830px;
}
.menuBg ::v-deep .el-menu-item.is-active {
  background: #10c55b;
  color: #fff;
  width: 230px;
  // margin-left: 20px;
  border-radius: 15px;
  box-shadow: 5px 8px 30px 1px rgba(48, 49, 51, 0.06);
  // text-align: center;
}
.menu1 ::v-deep .el-menu {
  background: #0C1738;
}
// .menu1 ::v-deep .el-menu-item.is-active {
//   color: #fff;
// }
// .menu1 ::v-deep .el-menu-item :hover{
//   color: #fff;
// }
.menu1 ::v-deep .el-menu--horizontal>.el-menu-item.is-active{
  border-bottom: 2px solid #10C55B;
}
.menu1 ::v-deep .el-menu.el-menu--horizontal{
  border-bottom: 1px solid #242E4C;
}
.menu1 ::v-deep .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover{
  background-color: #0C1738;
}
.menu1 ::v-deep .el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
  color: #fff;
}
.menu1 ::v-deep .el-menu--horizontal>.el-menu-item{
  color: #fff;
}

.primary-button {
  // position: absolute;
  // bottom: 30px;
  // width: 150px;
  text-align: center;
  // height: 48px;
  // padding: 5px 20px;
  // padding: 5px 28px;
  color: #ffffff;
  background: #10c55b;
  border-radius: 10px;
  display: inline-block;
  opacity: 1;
  cursor: pointer;

  a {
    text-decoration: none;
    color: #ffffff;
  }
}
</style>
