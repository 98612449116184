<template>
  <div class="fwhite mt50">
    <div>
      <el-row :gutter="50">
        <el-col :span="12">
          <div v-for="(item, index) in detailObj.list" :key="index" class="mb30">
            <div class="f18">{{ item.title }}</div>
            <div class="border-bottom mb10"></div>
            <div style="line-height: 32px;" class="color2AF">
              {{ item.content1 }}
              {{ item.content2 }}
              {{ item.content3 }}
              {{ item.content4 }}
            </div>
            <!-- <div v-show="item.list.length > 0">
              <el-row>
                <el-col :span="8" v-show="(item0, index0) in item.list" :key="index0">
                  <div class="tag">{{ item0 }}</div>
                </el-col>
              </el-row>
            </div> -->
          </div>
          <!-- <div>
            <div class="f18">酒店的任何商品、服务都可变成收入</div>
            <div class="border-bottom"></div>
            <div style="line-height: 32px;" class="color2AF">
              通过微信订房、移动商城，将酒店客房、餐饮、小商品、接送、
              洗衣、组合套餐等实物或虚拟产品明码标价。客人不管是在店还
              是离店，都可以通过在线完成交易，提高酒店营收。
            </div>
          </div> -->
          
        </el-col>
        <el-col :span="12">
          <el-image
            :src="detailObj.url"
          ></el-image>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activedIndex1: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      list1: [
        {
          title: '酒店的任何商品、服务都可变成收入',
          content1: '通过微信订房、移动商城，将酒店客房、餐饮、小商品、接送、',
          content2: '洗衣、组合套餐等实物或虚拟产品明码标价。客人不管是在店还',
          content3: '是离店，都可以通过在线完成交易，提高酒店营收。',
        },
        {
          title: '员工把做服务变成乐趣',
          content1: '服务好客人的员工，可以顺理成章的向客户推荐充值、商品。员',
          content2: '工通过分销系统提高业绩。排名、积分系统激发员工的好强心，',
          content3: '越干越有劲。',
        }
      ],
      list2: [
        {
          title: '营销能力+工具 让营销产生营收价值',
          content1: '支持多种营销活动（注册会员活动、满减、满送、充值活动，批量发券、入住）',
          content2: '活动、签到活动、老带新活动、动态房价活动、员工好评活动、报名活动、内',
          content3: '部邀请活动、短信营销、游戏活动等等），满足不同客户（OTA、散客、会员',
          content4: '等等）及不同渠道（门店、团购、小程序订房）的营销方案，实现营收。',
          list: [
            '注册会员活动',
            '动态房价活动',
            '储值活动',
            '订单活动',
            '入住活动',
            '签到活动',
            '老带新活动',
            '批量发券',
            '短信营销',
            '员工内部邀请'
          ],
        },
      ],
      list3: [
        {
          title: '多种会员体系 满足不同客户需求',
          content1: '会员种类：门店可以自建自己的会员体系，设置充值不同会员的余额',
          content2: '积分类型：按消息金额、按间夜、按消费',
          content3: '多种权益：延迟退房、生日倍数积分、早餐券、免押金等',
          content4: '微信转化：支持（免查房、接送、水果等）数十种权益展示',
        },
        {
          title: '会员分析系统 更懂客户',
          content1: '提供强大的会员分析系统、消费习惯分析、消费数据分析。并能',
          content2: '按客户的消费金额、入住频率、最近入住时间进行多重分析并分',
          content3: '组对不同价值客户群组进行不同的营销，提高客户的回头率。',
        }
      ],
    }
  },
  computed: {
    detailObj() {
      let obj = {}
      if (this.activedIndex1 == 1) {
        obj.list = this.list1
        obj.url = require('../../static/image/home/meunDetail10.png')
      } else if (this.activedIndex1 == 2) {
        obj.list = this.list2
        obj.url = require('../../static/image/home/meunDetail11.png')
      } else if (this.activedIndex1 == 3) {
        obj.list = this.list3
        obj.url = require('../../static/image/home/meunDetail12.png')
      }
      console.log('objobj', obj);
      return obj
    },
  },
}
</script>

<style lang="scss" scoped>
.border-bottom {
  width: 72px;
  height: 12px;
  background: linear-gradient(163deg, #10C55B 0%, #0C1738 100%);
  border-radius: 4px 4px 4px 4px;
  opacity: 0.6;
}
.tag {
  border-radius: 8px;
  padding: 10px 5px;
  font-size: 13px;
  margin: 5px 5px;
  border: 1px solid #3B476A;
}
</style>