<template>
  <div>
    <div class="img">
      <!-- <el-image :src="require('../../static/image/home/updateBg1.png')"></el-image> -->
      <el-image :src="bgUrl1"></el-image>
      <div class="home1Title">
        <div class="title fwhite">玩住虎客户案例</div>
        <div class="dec fwhite" :style="{'width': decWhidth + 'px' }">
          玩住虎是国内首家致力于为电竞酒店、网咖、餐饮等行业提供门店服务管理平台及场景化解决方案的综合服务商。
        </div>
        <div class="mt30">
          <el-button type="primary" class="btn" @click="goToDownload">立即下载</el-button>
        </div>
      </div>
    </div>
    <div class="pt90 pb50 bg7FA">
      <el-row :gutter="10">
        <el-col :span="16" :offset="4">
          <el-row :gutter="10" class="menuBg">
            <el-col :span="5">
              <div class="logContentBg">
                <div class="ftb mb10">更新日志版本</div>
                <el-menu
                  :default-active="activeIndex"
                  menu-trigger="hover"
                  class="el-menu-vertical-demo"
                  @select="handleOpen"
                >
                  <el-menu-item :index="index" v-for="(item, index) in menuList" :key="index">
                    <span slot="title">{{ item.version }}</span>
                  </el-menu-item>
                </el-menu>
              </div>
            </el-col>
            <el-col :span="19">
              <div class="logContentBg" v-for="(item, index) in menuDetailList" :key="index">
                <div>
                  <div class="f36 colorF27 ftb">{{ item.version }}</div>
                  <div class="color5A9 f16">发布日期：{{ item.date }}</div>
                  <el-divider></el-divider>
                </div>
                <div class="mb30">
                  <div>新增功能</div>
                  <!-- <div class="border-bottom mb10"></div> -->
                  <div>
                    <el-image :src="require('../../static/image/home/border-bottom.png')"></el-image>
                  </div>
                  <div class="content" v-for="(item1, index1) in item.newList" :key="index1">
                    {{ item1 }}
                  </div>
                </div>
                <div>
                  <div>优化功能</div>
                  <div>
                    <el-image :src="require('../../static/image/home/border-bottom.png')"></el-image>
                  </div>
                  <div class="content" v-for="(item2, index2) in item.OptimizationList" :key="index2">
                    {{ item2 }}
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../../src/components/footer.vue'

export default {
  components: {Footer},
  data() {
    return {
      bgUrl1:'https://wanzhuhu-1305683220.cos.ap-shanghai.myqcloud.com/website/updateBg1.png',
      decWhidth: 0,
      activeIndex: 0,
      activedIndex: 0,
      menuList: [
        {
          version: '2.2208.09版本',
          date:'2022-08-09',
          newList: [
            '新增涂鸦智能酒店对接服务',
            '新增管理端小程序查看会员漫游余额对账单',
            '新增客户端商品点购页面展示参与赠送奖品的商品',
            '新增客户端/小程序/pos点购商品使用优惠券不参与点购活动',
            '新增支持POS端/web后台完善会员资料信息',
            '新增酒店开房退房打印支持发票模板打印',
            '新增房间打扫支持优先打扫设置',
            '新增新版本网费充值自助插件',
          ],
          OptimizationList: [
            '优化酒店开房时出现退房时间不一致问题',
            '优化客户端卡券商品的显示',
            '优化修改已开房间开房人信息同步修改退房时间',
            '优化管理端小程序查看商品销售数据增加类别筛选',
            '优化管理端小程序营业数据跳转查看交班数据筛选问题',
            '优化联房时账单错乱问题',
            '优化酒店语音提醒信息',
            '优化商品点购订单不显示会员姓名',
            '优化预订转开房时开房价格问题',
            '优化门锁最大制卡数限制',
            '优化通过手机号录入会员信息流程',
            '优化门店在线支付列表显示',
            '优化快捷入住订单来源的选择样式',
            '优化通过会员手机号/姓名查询会员信息',
            '优化会员小程序特价房预订价格异常问题',
            '优化商品出库/商品跨店调拨库存记录异常',
            '优化员工考勤自动排班跨天时问题',
            '优化管理端小程序交班详情页面',
            '优化客户端签到活动展示',
            '优化其它已知bug',
          ],
        },
        {
          version: '2.2207.01版本',
          date:'2022-07-01',
          newList: [
            '新增租赁商品',
            '新增房间叫醒服务',
            '新增钟点房/全天房计费模式变更',
            '新增连锁门店积分通用功能',
            '新增单体门店/连锁门店会员漫游对账查询',
            '新增动态房价活动',
            '新增下级渠道关联业务员',
            '新增团购方式记录是美团现付方式，支付的时候需要跳转到在线支付',
          ],
          OptimizationList: [
            '优化开房时门店来源去掉小程序选项',
            '优化酒店开房订单导出功能，需要加入美团订单号',
            '优化房态盘筛选余额不足',
            '优化预定时退房时间不显示问题',
            '优化会员开房时退房时间不对问题',
            '优化客户端自动打印的问题',
            '优化考勤签到的问题',
            '优化吧台补货/仓库进货快捷键搜索商品',
            '优化商品进货报表关键字搜索',
            '优化修改预订单时转开房房价错误问题',
            '优化其它已知bug',
          ],
        },
        {
          version: '2.2206.16版本',
          date:'2022-06-16',
          newList: [
            '新增商品盘点模板',
            '新增小程序支持不同服务商/付呗支付',
            '新增会员消费习惯/客史记录/标签设置',
            '新增房间消费冲调功能',
            '新增初始老板账号切换功能',
            '新增交班房费/押金不同的支付方式汇总',
            '新增奖品折扣券的设置',
            '新增设置超时多长时间开始收超时费',
            '新增连锁后台中增加实物奖品',
          ],
          OptimizationList: [
            '优化会员小程序特价/优惠预订房间',
            '优化历史交班/当班查看交班在线数据汇总',
            '优化库存盘点/采购商品选择商品增加分类筛选',
            '优化会员网费充值出现重复充值的问题',
            '优化会员开房时退房时间不一致问题',
            '优化房间换房时出现价格异常的问题',
            '优化来订单时导致补货/盘点操作中断问题',
            '优化门店多次签到活动导致签到统计异常问题',
            '优化在线支付押金退款重复的问题',
            '优化管理端小程序权限显示门店经营数据',
            '优化商品销售报表支持商品小分类筛选',
            '优化抽奖活动页面显示活动详情',
            '优化商品销售报表支持商品小分类筛选',
            '优化会员小程序活动详情直接参与指定活动',
            '优化POS端协议单位/web协议单位结算功能',
            '优化会员充值/点购活动无法赠送优惠券功能',
            '优化门店小程序二维码展示门店定制小程序码',
            '优化资金管理筛选时间点资金数据以及导出功能',
            '优化部分门店身份证尾号为X的会员自动充值失败',
            '优化网费自动充值插件赠送奖励金额没有操作员问题',
            '优化自定义收款账户名称过长导致无法自动完成订单问题',
            '优化管理端小程序酒店订单新增各类筛选',
            '优化联房订单消费详细结算问题',
            '优化pos端实时显示盘点消耗品的库存数量',
            '优化门店支付通道切换不成功的问题',
            '优化其它已知bug',
          ],
        },
        {
          version: '2.2205.23版本',
          date:'2022-05-23',
          newList: [
            '新增商品加料/大小杯方案加价功能',
            '新增批量开房',
            '新增联房功能',
            '新增第三方支付-付呗支付',
            '新增会员等级设置不同退房时间',
            '新增OTA来源可单独设置退房时间',
            '新增商品进货报表',
            '新增员工业绩报表',
            '新增员工手机端完成吧台待支付订单',
            '新增查房/完成打扫只推送当班人员设置',
            '新增允许网费充值订单取消',
            '新增会员小程序商品点购模板',
            '新增会员等级可设置不同的房价折扣',
            '新增批量下载扫码点单桌贴码',
          ],
          OptimizationList: [
            '优化房价牌实时显示特价房价格',
            '优化房价牌显示小程序预订价格',
            '优化客户端右侧菜单栏部分显示问题',
            '优化pos端切换会员/协议单位价格显示问题',
            '优化客户端硬件资产信息上传',
            '优化部分支付渠道退款异常问题',
            '优化当班员工显示以及只允许当班员工接受推送',
            '优化交班统计吧台收款自动计算其它收入里面',
            '优化销售统计盘点出现重复盘点的问题',
            '优化修改预订单时转开房房价错误问题',
            '优化商品点购订单微信推送审核小程序链接',
            '优化连锁后台商品同步至门店数据未更新问题',
            '优化小程序/客户端预订房间可用优惠券不显示问题',
            '优化网费漫游不成功问题',
            '优化协议单位续房/换房价格问题',
            '优化pos端商品订单累计积分问题',
            '优化已完成订单出现处理时间为空的问题',
            '优化单笔充值会员等级自动升级问题',
            '修复吧台收款不能退款问题',
            '优化其它已知bug',
          ],
        },
        {
          version: '2.2204.20版本',
          date:'2022-04-20',
          newList: [
            '新增智能电子房价牌',
            '新增会员卡累计自动升级功能',
            '新增对接酒店公安信息上传功能',
            '新增网费购买商品功能',
            '新增客户端商品排序功能',
            '新增商品设置是否允许会员余额购买',
            '新增客户端广告位自定义上传'
          ],
          OptimizationList: [
            '优化员工自动排班功能',
            '优化酒店换房不使用预付款抵扣功能',
            '优化预订单详情页面',
            '优化日程推送功能',
            '优化其它已知bug',
          ],
        },
        {
          version: '2.2203.15版本',
          date:'2022-03-15',
          newList: [
            '新增房态盘房间快捷操作/房间详情显示',
            '新增房态盘房费不足/预付款不足显示',
            '新增房间增收押金',
            '新增会员卡统计报表',
            '新增职位功能权限的应用',
            '新增小程序服务商支付/支付门店申请独立的商户号支付',
            '新增客户端扫码解锁限制不同会员等级',
            '新增客户端锁屏快捷登录验证身份证号码',
            '新增web后台查看教程',
            '新增下机清理设置规定时间段播报',
          ],
          OptimizationList: [
            '优化预订OTA房间输入团购码',
            '优化吧台补货选择商品增加分类筛选',
            '优化吧台商品排序的应用',
            '优化添加预付款/续房支付时操作人显示问题',
            '优化会员端小程序套餐商品详情显示',
            '优化桌面锁无法锁屏问题',
            '优化交班查看交班在线支付不同支付方式汇总',
            '优化会员消费没有积分的问题',
            '优化游戏周边商品无法进行进货和补货问题',
            '优化房态盘今离的房态筛选',
            '优化房态盘简洁/详情模式切换问题',
            '优化会员端小程序取消预订单功能',
            '优化部分型号手机管理端小程序无法进行拍照上传图片问题',
            '优化部分员工账号签到后，无法正常签退问题',
            '优化其它已知bug',
          ],
        },
        {
          version: '2.2202.22版本',
          date:'2022-02-22',
          newList: [
            '新增退房自动打印',
            '新增远期房态取消预订',
            '新增房间预付款列表退款操作',
            '新增房间待补货商品数量显示',
            '新增修改房态预订单信息',
            '新增协议单位结算账单直接支付',
            '新增客户端群发消息功能',
            '新增货到付款填写预付现金金额',
            '新增会员小程序使用网费券​',
            '新增开房自动计算每日房价',
          ],
          OptimizationList: [
            '优化小程序预订同时预订问题',
            '优化商品销售汇总统计问题',
            '优化商品不同口味设置问题',
            '优化客户端查看活动详情',
            '优化POS端预订房间填写预订价格',
            '优化管理端小程序补货功能',
            '优化会员端小程序门店详情UI',
            '优化房间销售报表',
            '优化团购码显示',
            '优化其它已知bug',
          ],
        },
        {
          version: '2.2202.10版本',
          date:'2022-02-10',
          newList: [
            '新增POS端会员等级购买',
            '新增回收卡券功能',
            '新增超时房间允许换房',
            '新增员工账号扫码登录客户机',
            '新增查看单个房间远期房态',
            '新增房间日志查询',
            '新增预订单修改/转续房操作',
            '新增会员小程序到店支付预订房间',
            '新增预订房态下制卡/完成打扫操作',
            '新增允许部分房型不锁屏设置',
            '新增管理端小程序/web端查看顾客呼叫留言',
            '新增管理端小程序/web端查看房间日志',
            '新增POS端预订房间输入房间价格',
            '新增客户端报名活动/员工点评入口',
            '新增协议账户小程序预订'
          ],
          OptimizationList: [
            '优化远期房态显示',
            '优化pubwin插件读取身份证',
            '优化营业收入/报表汇总自定义收款显示',
            '优化员工考勤考勤区域限制',
            '优化营销统计-卡券统计',
            '优化POS端预订房间显示问题',
            '优化客户端现做商品点购不同口味问题',
            '优化本班上班交班数据退款处理问题',
            '优化POS端续房页面以及入账问题',
            '优化POS端打印奔溃以及打印缓慢问题',
            '优化会员小程序查询距离最近的门店',
            '优化POS端开房使用优惠券的问题',
            '优化房间商品挂账打印无法显示房间号问题',
            '优化客户端自动登录问题',
            '优化自行采购无法确认收货以及供应商不显示问题',
            '优化房间详情页面',
            '优化其它已知bug',
          ],
        },
        {
          version: '2.2201.11版本',
          date:'2022-01-11',
          newList: [
            '新增员工好评绩效',
            '新增员工好评活动',
            '新增报名活动',
            '新增内部员工邀请会员活动',
            '新增房间易耗品报表',
            '新增员工打扫报表汇总',
            '新增酒店公共安全审计对接',
            '新增自定义房态盘颜色',
            '新增协议账户协议人管理',
            '新增协议账户小程序酒店预订',
            '新增协议账户小程序预订',
          ],
          OptimizationList: [
            '优化会员小程序UI页面',
            '优化自定义账户支付押金',
            '优化酒店特惠预订价格显示',
            '优化酒店关联支付订单问题',
            '优化资金账户转入转出问题',
            '优化酒店当天房间预订问题',
            '优化商品超市商品分类新增问题',
            '优化其它已知bug',
          ],
        },
        {
          version: '2.2112.14版本',
          date:'2021-12-14',
          newList: [
            '新增协议单位功能',
            '新增POS端协议单位消费收款',
            '新增POS端自定义账户收款功能',
            '新增客户端订单自动打印',
            '新增商品出库功能',
            '新增商品调拨功能',
            '新增自定义仓库功能',
            '新增会员小程序自助续房',
            '新增会员消费扣除余额',
            '新增非入住房态下控制房间机器',
            '新增查看房间机器上下机记录',
            '新增POS端自定义控制菜单',
          ],
          OptimizationList: [
            '优化网费会员查询新版本适配问题',
            '优化网费漫游出现转出异常的问题',
            '优化交班内容实收收款项目内容',
            '优化资金账户筛选显示汇总数据',
            '优化续房时退房时间不自动调整问题',
            '优化酒店预订取消时资金统计问题',
            '优化其它已知bug',
          ],
        },
        {
          version: '2.2111.26版本',
          date:'2021-11-26',
          newList: [
            '新增网费漫游账户结算',
            '新增短信群发营销功能',
            '新增批量发券时短信群发通知',
            '新增客户端自助续房功能',
            '新增客户端锁屏扫码解锁功能',
            '新增下机清理打扫拍照上传功能',
            '新增自定义收款账户功能',
            '新增pos端数字小角标主动清除',
            '新增客户端网费充值/购买商品强制登录开关',
          ],
          OptimizationList: [
            '优化账户结算功能',
            '优化续房时退房时间出现异常问题',
            '优化连锁版小程序显示附近的门店',
            '优化其它已知bug',
          ],
        },
        {
          version: '2.2111.11版本',
          date:'2021-11-11',
          newList: [
            '新增门店资金账户转入/转出/结算功能',
            '新增连锁门店余额通用功能',
            '新增连锁门店余额结算功能',
            '新增房间商品订单取消单个商品功能',
            '新增酒店续房不抵扣预付款功能',
            '新增酒店续房单独支付房费功能',
            '新增酒店换房修改每天房费功能',
            '新增取消使用优惠券/取消使用积分抵扣功能',
            '新增酒店先开房后入账功能',
          ],
          OptimizationList: [
            '优化客户端/会员小程序连锁门店会员余额通用',
            '优化商品订单取消审核流程',
            '优化房间商品补货记录显示',
            '优化其它已知bug',
          ],
        },
        {
          version: '2.2111.01版本',
          date:'2021-11-01',
          newList: [
            '新增房间商品消费取消操作',
            '新增房间消费结算功能',
            '新增取消使用的优惠券/积分功能',
            '新增脏房预订功能',
          ],
          OptimizationList: [
            '优化网费重试充值功能',
            '优化餐饮桌态盘显示',
            '优化退单操作',
            '优化其它已知bug',
          ],
        },
        {
          version: '2.2110.21版本',
          date:'2021-10-21',
          newList: [
            '新增餐饮功能模块',
            '新增会员小程序扫码点餐功能',
            '新增主副吧台功能',
            '新增管理端小程序营业数据查看',
          ],
          OptimizationList: [
            '优化连锁签到活动',
            '优化批量发券活动',
            '优化在线超时支付问题',
            '优化管理端小程序门店设置模块',
            '优化房间商品补货流程',
            '优化商品/房费追加优惠券功能',
            '优化订单/押金退款问题',
            '修复POS端卡顿问题',
            '修复网费充值重复问题',
            '修复员工账号绑定微信问题',
            '修复考勤异常显示问题',
            '优化其它已知bug',
          ],
        },
        {
          version: '2.2109.30版本',
          date:'2021-09-30',
          newList: [
            '新增右侧菜单栏实时显示上机时间/余额',
            '新增商品折扣价格/会员价格',
            '新增房间商品追加使用优惠券',
            '新增房费追加使用优惠券',
            '新增损坏赔偿功能',
            '新增网费充值订单打印',
            '新增酒店开房订单恢复功能',
            '新增批量修改商品超时商品',
            '新增门店人员解绑功能',
            '新增单体门店/连锁门店批量发券活动',
            '新增网费充值自定义充送规则',
            '新增必达门锁软件对接功能',
            '新增客户端各个功能菜单开关限制',
          ],
          OptimizationList: [
            '优化全屏虚拟桌面功能',
            '优化网费漫游功能',
            '优化连锁商品导入功能',
            '优化签到活动上机时长限制功能',
            '优化后台会员充值功能',
            '优化网费直冲券功能',
            '优化门店基础设置/客户端设置/pos端设置',
            '优化其它已知bug',
          ],
        },
        {
          version: '2.2109.02版本',
          date:'2021-09-02',
          newList: [
            '新增全屏虚拟桌面功能',
            '新增房间损坏赔偿功能',
            '新增商品购买校验功能',
          ],
          OptimizationList: [
            '优化小程序查房功能',
            '优化商品补货和吧台上架功能',
            '优化网费漫游结算功能',
            '优化挂机锁功能',
            '优化虚拟桌面图标显示',
            '优化其它已知bug',
          ],
        },
        {
          version: '2.2108.25版本',
          date:'2021-08-25',
          newList: [
            '新增管理端小程序菜单权限控制',
            '新增首次入住活动/入住活动',
            '新增优惠券使用次数限制',
            '新增查房功能',
            '新增批量发券活动',
            '新增客户端进程保护',
            '新增房间商品追加使用优惠券',
            '新增房间房费追加使用优惠券',
          ],
          OptimizationList: [
            '优化连锁门店奖品功能',
            '优化pos端交班打印消耗品',
            '优化修改商品库存',
            '优化吧台补货功能',
            '优化新版房态图功能',
            '优化管理端小程序日程管理',
            '优化其它已知bug',
          ],
        },
        {
          version: '2.2108.18版本',
          date:'2021-08-18',
          newList: [
            '新版酒店模块房态盘发布',
            '新增套餐商品售卖查看详情',
            '新增客户端快捷回复功能',
          ],
          OptimizationList: [
            '优化续房、添加预付款流程问题',
            '优化添加门店会员功能',
            '优化快捷搜索会员和非会员功能',
            '优化交班本班留存问题',
            '优化网费充值重复充值问题',
            '优化其它已知bug',
          ],
        },
        {
          version: '2.2108.09版本',
          date:'2021-08-09',
          newList: [
            '新增疫情防控提示语音播放提醒',
            '新增语音支付声音开关控制',
            '新增远程控制房间电脑',
            '新增添加会员充值限制',
            '新增左侧消息通知数量显示',
            '新增查看当班在线支付金额列表',
            '新增客户端余额充值功能',
          ],
          OptimizationList: [
            '优化会员充值自动升级会员功能',
            '优化网费手动充值订单功能',
            '优化注册门店会员身份证检验功能',
            '优化打印功能模块开关控制',
            '优化商品点购商品列表显示',
            '优化其它已知bug',
          ],
        },
        {
          version: '2.2107.26版本',
          date:'2021-07-26',
          newList: [
            '新增客户端自动登录',
            '新增网费漫游重试',
            '新增吧台各类商品排序/销售统计/盘点/交班商品排序功能',
            '新增套餐商品默认图片',
            '新增酒店房间电脑一机一证功能',
            '新增酒店房间超时锁屏、关机、重启功能',
            '新增酒店房间电脑锁屏自动关机、重启、锁屏功能',
            '新增交班/开房订单自定义打印设置',
            '新增网费订单打印',
            '新增小程序快速跳转最近消费门店或者距离最近',
            '新增管理端小程序房态盘新版页面显示',
            '新增管理端小程序当班销售盘点',
            '新增管理端小程序房间增加消费功能',
          ],
          OptimizationList: [
            '优化客户端商品非销售时段商品不能销售',
            '优化客户端/虚拟桌面/后台/小程序分享/抽奖页面',
            '门店小程序二维码显示',
            '优化下机清理',
            '优化房间房型销售报表优化',
            '优化管理端小程序房间销售汇总',
            '优化下机清理功能',
            '优化商品点购扫码使用优惠券功能',
            '优化其它已知bug',
          ],
        },
      ],
    }
  },
  computed: {
    menuDetailList() {
      console.log('this.activedIndex', this.activedIndex);
      let list = []
      list.push(this.menuList[this.activedIndex])
      return list
    },
  },
  mounted() {
    this.screenWidth = document.documentElement.clientWidth
    this.decWhidth = (496 / 1920) * this.screenWidth
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth
      this.decWhidth = (496 / 1920) * this.screenWidth
    }
    console.log('decWhidth', this.decWhidth, 'screenWidth', this.screenWidth)
    const customer1 = document.querySelector('.customer1')

    console.log('customer1',customer1 );
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log('handleOpen', key, keyPath, this.activeIndex)
      this.activedIndex = key
    },
    goToDownload() {
      this.$router.push({
        path: '/download',
        query:{id:4 },
        // name: 'download',
        // params:{id:4 },
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.img {
  position: relative;

  .el-image {
    display: flex;
  }
  .home1Title {
    position: absolute;
    top: 35%;
    left: 20%;
  }
  .home3Title {
    position: absolute;
    top: 16%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.dec {
  line-height: 30px;
  font-size: 16px;
}
.btn {
  padding: 10px 42px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
}
.menuBg ::v-deep .el-menu-item.is-active {
  background: #10c55b;
  color: #fff;
  // width: 220px;
  // margin-left: 20px;
  border-radius: 12px;
  box-shadow: 5px 8px 30px 1px rgba(48, 49, 51, 0.06);
  // text-align: center;
}
.menuBg ::v-deep .el-menu-item{
  height: 42px;
  line-height: 42px;
}
.menuBg ::v-deep .el-menu{
  border-right: none;
}
.logContentBg {
  background: #fff;
  box-shadow: 5px 8px 30px 1px rgba(48,49,51,0.06);
  border-radius: 20px;
  padding: 40px 30px;
  height:800px;
  overflow: auto;
}
.border-bottom {
  width: 72px;
  height: 12px;
  background: linear-gradient(163deg, #10C55B 0%, #0C1738 100%);
  border-radius: 4px 4px 4px 4px;
  opacity: 0.6;
}
.content {
  line-height: 32px;
}
</style>