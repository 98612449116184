<template>
  <div>
    <div class="img">
      <el-image :src="require('../../static/image/home/customBanner.png')"></el-image>
      <div class="home1Title">
        <div class="title fwhite">玩住虎客户案例</div>
        <div class="dec fwhite" :style="{'width': decWhidth + 'px' }">
          玩住虎是国内首家致力于为电竞酒店、网咖、餐饮等行业提供门店服务管理平台及场景化解决方案的综合服务商。
        </div>
        <div class="mt30">
          <el-button type="primary" class="btn" @click="goToDownload">立即下载</el-button>
        </div>
      </div>
    </div>
    <div class="pt90 pb50">
      <div class="mb30 align-c">
        <div class="title">我们的案例</div>
        <div class="subTitle">想客户之想，急客户所急</div>
      </div>
      <el-row>
        <el-col :span="14" :offset="5">
          <el-row :gutter="20">
            <el-col :span="8" class="mb20" v-for="(item, index) in list" :key="index">
              <div class="customerbg">
                <el-image
                  class="customer1"
                  :src="item.url"
                ></el-image>
                <div class="ml20">
                  <div class="colorF27 f20 mb10">
                    {{ item.name }}
                  </div>
                  <div class="addr">{{ item.addr }}</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../../src/components/footer.vue'
export default {
  components: {Footer},
  data() {
    return {
      screenWidth: 0,
      decWhidth: 0,

      list: [
        {
          url: require('../../static/image/customCase/hotel1.png'),
          name: 'SKR电竞酒店',
          addr: '安徽省合肥市庐江县S319(周瑜大道)',
        },
        {
          url: require('../../static/image/customCase/hotel2.png'),
          name: 'TI电竞酒店',
          addr: '河南省许昌市葛市颍川路',
        },
        {
          url: require('../../static/image/customCase/hotel3.png'),
          name: 'GS电竞酒店',
          addr: '安徽省安庆市大观区德亮路277号GS电竞酒店',
        },
        {
          url: require('../../static/image/customCase/hotel4.png'),
          name: '超神电竞酒店',
          addr: '安徽省安庆市大观区德亮路277号GS电竞酒店',
        },
        {
          url: require('../../static/image/customCase/hotel5.png'),
          name: '贝肯熊电竞酒店',
          addr: '安徽省安庆市大观区德亮路277号GS电竞酒店',
        },
        {
          url: require('../../static/image/customCase/hotel6.png'),
          name: '砂之船艺术酒店',
          addr: '安徽省安庆市大观区德亮路277号GS电竞酒店',
        },
        {
          url: require('../../static/image/customCase/hotel7.png'),
          name: '星座电竞酒店',
          addr: '安徽省安庆市大观区德亮路277号GS电竞酒店',
        },
        {
          url: require('../../static/image/customCase/hotel8.png'),
          name: 'TI电竞酒店',
          addr: '安徽省安庆市大观区德亮路277号GS电竞酒店',
        },
        {
          url: require('../../static/image/customCase/hotel9.png'),
          name: '风客电竞酒店',
          addr: '安徽省安庆市大观区德亮路277号GS电竞酒店',
        },
        {
          url: require('../../static/image/customCase/hotel10.png'),
          name: '众鑫电竞酒店',
          addr: '安徽省安庆市大观区德亮路277号GS电竞酒店',
        },
        {
          url: require('../../static/image/customCase/hotel11.png'),
          name: '古丰电竞酒店',
          addr: '安徽省安庆市大观区德亮路277号GS电竞酒店',
        },
        {
          url: require('../../static/image/customCase/hotel12.png'),
          name: '至尚电竞酒店',
          addr: '安徽省安庆市大观区德亮路277号GS电竞酒店',
        },
        {
          url: require('../../static/image/customCase/hotel13.png'),
          name: '青归酒店',
          addr: '安徽省安庆市大观区德亮路277号GS电竞酒店',
        },
        {
          url: require('../../static/image/customCase/hotel14.png'),
          name: 'TH三里元店',
          addr: '安徽省安庆市大观区德亮路277号GS电竞酒店',
        },
        {
          url: require('../../static/image/customCase/hotel15.png'),
          name: '阿尔法电竞酒店',
          addr: '安徽省安庆市大观区德亮路277号GS电竞酒店',
        },
      ],
    }
  },

  mounted() {
    this.screenWidth = document.documentElement.clientWidth
    this.decWhidth = (496 / 1920) * this.screenWidth
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth
      this.decWhidth = (496 / 1920) * this.screenWidth
    }
    console.log('decWhidth', this.decWhidth, 'screenWidth', this.screenWidth)
    const customer1 = document.querySelector('.customer1')

    console.log('customer1',customer1.width );
  },
  methods: {
    goToDownload() {
      this.$router.push({
        path: '/download',
        query:{id:4 },
        // name: 'download',
        // params:{id:4 },
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.img {
  position: relative;

  .el-image {
    display: flex;
  }
  .home1Title {
    position: absolute;
    top: 35%;
    left: 20%;
  }
  .home3Title {
    position: absolute;
    top: 16%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.dec {
  line-height: 30px;
  font-size: 16px;
}
.btn {
  padding: 10px 42px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
}
.customerbg {
  box-shadow: 5px 8px 30px 1px rgba(48,49,51,0.0600);
  border-radius: 20px;
  padding-bottom: 20px;
  // position: relative;
}
.addr {
  font-size: 13px;
  color: #7D818A;
  word-wrap: nowrap;
  text-emphasis: over;
  overflow: hidden;
}
</style>