import { render, staticRenderFns } from "./updateLog.vue?vue&type=template&id=5b84a47f&scoped=true&"
import script from "./updateLog.vue?vue&type=script&lang=js&"
export * from "./updateLog.vue?vue&type=script&lang=js&"
import style0 from "./updateLog.vue?vue&type=style&index=0&id=5b84a47f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b84a47f",
  null
  
)

export default component.exports