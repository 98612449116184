<template>
  <div>
    <div class="img">
      <el-image
        :src="require('../../static/image/home/downloadbg1.png')"
      ></el-image>
      <div class="home1Title">
        <div class="title fwhite">玩住虎产品中心</div>
        <div class="dec fwhite" :style="{'width': decWhite + 'px'}">
          从C端用的“吃、住、玩”到B端用户的“运、管、营”，用户才是玩住虎门店管理系统的产品价值塑造者，我们不创造价值，我们只搬运价值。
        </div>
        <div class="mt30">
          <!-- <el-button type="primary" class="btn">
            <a href="#download">立即下载</a>
          </el-button> -->
          <div id="div">
            <a
              id="aid"
              class="aid btn"
              href="#download"
              ><span id="span">立即下载</span></a>
          </div>
        </div>
      </div>
    </div>
    <div class="bg2 className" id="download">
      <div class="align-c mb50">
        <div class="title">我们的产品</div>
        <div class="subTitle">想客户之想，急客户之所急</div>
      </div>
      <el-row class="pb40">
        <el-col :span="16" :offset="4">
          <el-row :gutter="20">
            <el-col :span="6" style="display: none">
              <div class="downloadBg">
                <el-image
                    class="mb20"
                    :src="require('../../static/image/home/posIcon.png')"
                ></el-image>
                <div class="mb10 colorF27">服务端（新）</div>
                <div class="text-dec">
                  <div>更新版本：V2.230801.2</div>
                  <div>更新时间：2023-08-01</div>
                </div>
                <div class="mt20">
                  <div class="primary-button padding15">
                    <!-- <i class="el-icon-download"></i> -->
                    <el-image
                        class="downloadImg"
                        :src="require('../../static/image/home/download.png')"
                    ></el-image>
                    <a
                        href="http://www.51xuanzhu.com/Upfiles/down/%E6%9C%8D%E5%8A%A1%E7%AB%AF.zip"
                    ><font>完整包</font></a>
                  </div>
                 <!-- <div class="primary-button padding15">
                    &lt;!&ndash; <i class="el-icon-download"></i> &ndash;&gt;
                    <el-image
                        class="downloadImg"
                        :src="require('../../static/image/home/download.png')"
                    ></el-image>
                    <a
                        href="https://wanzhuhu-1305683220.cos.ap-shanghai.myqcloud.com/download/BarposUpdate230411.exe"
                    ><font>升级包</font></a>
                  </div>-->
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="downloadBg">
                <el-image
                  class="mb20"
                  :src="require('../../static/image/home/posIcon.png')"
                ></el-image>
                <div class="mb10 colorF27">收银端（新）</div>
                <div class="text-dec">
                  <div>更新版本：V2.230801.2</div>
                  <div>更新时间：2023-08-01</div>
                </div>
                <div class="mt20">
                  <div class="primary-button padding15">
                    <!-- <i class="el-icon-download"></i> -->
                    <el-image
                      class="downloadImg"
                      :src="require('../../static/image/home/download.png')"
                    ></el-image>
                    <a
                      href="http://www.51xuanzhu.com/Upfiles/down/8001.zip"
                      ><font>完整包</font></a>
                  </div>
                 <!-- <div class="primary-button padding15">
                    &lt;!&ndash; <i class="el-icon-download"></i> &ndash;&gt;
                    <el-image
                      class="downloadImg"
                      :src="require('../../static/image/home/download.png')"
                    ></el-image>
                    <a
                      href="https://wanzhuhu-1305683220.cos.ap-shanghai.myqcloud.com/download/BarposUpdate230411.exe"
                      ><font>升级包</font></a>
                  </div>-->
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="downloadBg">
                <el-image
                  class="mb20"
                  :src="require('../../static/image/home/clientIcon.png')"
                ></el-image>
                <div class="mb10 colorF27">客户端（新）</div>
                <div class="text-dec">
                  <div>更新版本：V2.230801.2</div>
                  <div>更新时间：2023-08-01</div>
                </div>
                <div class="mt20">
                  <div class="primary-button padding15">
                    <!-- <i class="el-icon-download"></i> -->
                    <el-image
                      class="downloadImg"
                      :src="require('../../static/image/home/download.png')"
                    ></el-image>
                    <a
                      href="http://www.51xuanzhu.com/Upfiles/down/8002.zip"
                      ><font>完整包</font></a>
                  </div>
                 <!-- <div class="primary-button padding15">
                    &lt;!&ndash; <i class="el-icon-download"></i> &ndash;&gt;
                    <el-image
                      class="downloadImg"
                      :src="require('../../static/image/home/download.png')"
                    ></el-image>
                    <a
                      href="https://wanzhuhu-1305683220.cos.ap-shanghai.myqcloud.com/download/BarClientUpdate221205.exe"
                      ><font>升级包</font></a>
                  </div>-->
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="downloadBg">
                <el-image
                  class="mb20"
                  :src="require('../../static/image/home/manageIcon.png')"
                ></el-image>
                <div class="mb10 colorF27">玩住虎酒店云管家公众号（新）</div>
                <div class="text-dec">
                  <div>微信扫一扫</div>
                </div>
                <div class="mt20" @click="scanClick(3)">
                  <div class="primary-button padding28">
                    <el-image
                      class="downloadImg"
                      :src="require('../../static/image/home/qrcode.png')"
                    ></el-image>
                    <span>点击扫码</span>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="16" :offset="4">
          <el-row :gutter="20">
            <el-col :span="6">
              <div class="downloadBg">
                <el-image
                    class="mb20"
                    :src="require('../../static/image/home/posIcon.png')"
                ></el-image>
                <div class="mb10 colorF27">POS端（前台收银机）老</div>
                <div class="text-dec">
                  <div>更新版本：V2.230411.2</div>
                  <div>更新时间：2023-04-11</div>
                </div>
                <div class="mt20 rowFB">
                  <div class="primary-button padding15">
                    <!-- <i class="el-icon-download"></i> -->
                    <el-image
                        class="downloadImg"
                        :src="require('../../static/image/home/download.png')"
                    ></el-image>
                    <a
                        href="https://wanzhuhu-1305683220.cos.ap-shanghai.myqcloud.com/download/BarposV2.230411.2.rar"
                    ><font>完整包</font></a>
                  </div>
                  <div class="primary-button padding15">
                    <!-- <i class="el-icon-download"></i> -->
                    <el-image
                        class="downloadImg"
                        :src="require('../../static/image/home/download.png')"
                    ></el-image>
                    <a
                        href="https://wanzhuhu-1305683220.cos.ap-shanghai.myqcloud.com/download/BarposUpdate230411.exe"
                    ><font>升级包</font></a>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="downloadBg">
                <el-image
                    class="mb20"
                    :src="require('../../static/image/home/clientIcon.png')"
                ></el-image>
                <div class="mb10 colorF27">客户端（PC客户机）老</div>
                <div class="text-dec">
                  <div>更新版本：V2.221205.2</div>
                  <div>更新时间：2022-12-05</div>
                </div>
                <div class="mt20 rowFB">
                  <div class="primary-button padding15">
                    <!-- <i class="el-icon-download"></i> -->
                    <el-image
                        class="downloadImg"
                        :src="require('../../static/image/home/download.png')"
                    ></el-image>
                    <a
                        href="https://wanzhuhu-1305683220.cos.ap-shanghai.myqcloud.com/download/BarClientV2.221205.2.rar"
                    ><font>完整包</font></a>
                  </div>
                  <div class="primary-button padding15">
                    <!-- <i class="el-icon-download"></i> -->
                    <el-image
                        class="downloadImg"
                        :src="require('../../static/image/home/download.png')"
                    ></el-image>
                    <a
                        href="https://wanzhuhu-1305683220.cos.ap-shanghai.myqcloud.com/download/BarClientUpdate221205.exe"
                    ><font>升级包</font></a>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="downloadBg">
                <el-image
                    class="mb20"
                    :src="require('../../static/image/home/manageIcon.png')"
                ></el-image>
                <div class="mb10 colorF27">管理端小程序 老</div>
                <div class="text-dec">
                  <div>微信扫一扫</div>
                </div>
                <div class="mt20" @click="scanClick(1)">
                  <div class="primary-button padding28">
                    <el-image
                        class="downloadImg"
                        :src="require('../../static/image/home/qrcode.png')"
                    ></el-image>
                    <span>点击扫码</span>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="downloadBg">
                <el-image
                    class="mb20"
                    :src="require('../../static/image/home/memberIcon.png')"
                ></el-image>
                <div class="mb10 colorF27">会员端小程序 老</div>
                <div class="text-dec">
                  <div>
                    微信扫一扫
                  </div>
                </div>
                <div class="mt20" @click="scanClick(2)">
                  <div class="primary-button padding28">
                    <el-image
                        class="downloadImg"
                        :src="require('../../static/image/home/qrcode.png')"
                    ></el-image>
                    <span>点击扫码</span>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <div class="align-c subTitle mt20">
        升级包使用提示：升级包下载到程序目录下 直接运行即可
      </div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="400px"
      id="dialog"
      :before-close="handleClose"
    >
      <div class="qrCode">
        <el-image :src="url"></el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../components/footer'
export default {
  components: { Footer },
  data() {
    return {
      title: '管理端小程序',
      url: '',
      dialogVisible: false,
      screenWidth: 0,
      decWhite: 0,
      isTo: false,
    }
  },
  watch:{
    $route: {
      handler(newValue, oldValue) {
        console.log('watch开始执行oldValue', oldValue)
        console.log('watch开始执行newValue', newValue)
        if (newValue.query.id == 4) {
          this.isTo = true
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    
  },
  destroyed() {
    // 回到顶部
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  mounted() {
    // 首次加载时,初始化高度
    // this.screenWidth = window.innerWidth
    this.screenWidth = document.documentElement.clientWidth
    this.decWhite = (460 / 1920) * this.screenWidth
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth
      this.decWhite = (460 / 1920) * this.screenWidth
    }
    console.log('decWhite', this.decWhite, 'screenWidth', this.screenWidth)

    
    setTimeout(() => {
      if (this.isTo) {
        window.location.hash = "#download";
        // this.$el.querySelector('#download').scrollIntoView()
      }
      
    }, 200);
  },
  methods: {
    scanClick(e) {
      this.dialogVisible = true
      if (e === 1) {
        this.title = '管理端小程序'
        this.url =
          'https://qbsupdte.oss-cn-shanghai.aliyuncs.com/wanzhuhu/web/manager.jpg'
        // this.url =
        //   'https://wanzhuhu-1305683220.cos.ap-shanghai.myqcloud.com/panda/manager.png'
      } else if (e === 2) {
        this.title = '会员端小程序'
        this.url =
          'https://qbsupdte.oss-cn-shanghai.aliyuncs.com/wanzhuhu/web/member.jpg'
        // this.url =
        //   'https://wanzhuhu-1305683220.cos.ap-shanghai.myqcloud.com/panda/member.png'
      }
      else if (e === 3) {
        this.title = '公众号'
        this.url =
            'https://wanzhuhu-1305683220.cos.ap-shanghai.myqcloud.com/web/qrcodenew.jpg'
        // this.url =
        //   'https://wanzhuhu-1305683220.cos.ap-shanghai.myqcloud.com/panda/member.png'
      }
    },
    handleClose() {
      this.dialogVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.img {
  position: relative;
  .el-image {
    display: flex;
  }
  .home1Title {
    position: absolute;
    top: 35%;
    left: 20%;
    // top: 20%;
    // left: 50%;
    // transform: translate(-50%, -50%);
  }
  // .home2Title {
  //   position: absolute;
  //   top: 20%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  // }
}
.dec {
  // width: 520px;
  line-height: 30px;
  font-size: 16px;
}
.bg2 {
  background: #F5F7FA;
  padding-top: 90px;
  padding-bottom: 107px;
}
.downloadBg {
  // width: 165px;
  padding: 57px 30px;
  // padding: 57px 60px;
  background: #fff;
  border-radius: 15px;
  text-align: center;
  box-shadow: 5px 8px 30px 1px rgba(48,49,51,0.0600);
  // position: relative;

  .text-dec {
    color: #7D818A;
    font-size: 12px;
    line-height: 24px;
    height: 41px;
  }
  
}
.primary-button {
  // position: absolute;
  // bottom: 30px;
  // width: 150px;
  text-align: center;
  // height: 48px;
  // padding: 5px 20px;
  // padding: 5px 28px;
  color: #ffffff;
  background: #10c55b;
  border-radius: 10px;
  display: inline-block;
  opacity: 1;
  cursor: pointer;

  
}
a {
  text-decoration: none;
  color: #ffffff;
}
.padding15 {
  padding: 5px 15px;
}
.padding28 {
  padding: 5px 28px;
}
.downloadImg {
  vertical-align: middle !important; 
  width: 25px;
}
.btn {
  color: #ffffff;
  background: #10c55b;
  padding: 10px 42px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
}
#dialog ::v-deep .el-dialog {
  border-radius: 10px;
  .qrCode {
    width: 260px;
    margin: auto;
  }
}
// #dialog ::v-deep.el-dialog__headerbtn .el-dialog__close {
//   font-size: 21px;
// }
#dialog ::v-deep .el-dialog__headerbtn {
  font-size: 21px;
}
</style>
