<template>
  <div>
    <div class="img">
      <el-image
        :src="require('../../static/image/home/barBanner.png')"
      ></el-image>
      <div class="home1Title">
        <div class="title fwhite">玩住虎网咖系统</div>
        <div class="dec fwhite" :style="{'width': decWhite + 'px'}">
          网咖，连锁网咖的生意参谋，多样化营销系统，助力网咖创收，营销能力+工具，让营销产生营收价值，我们一直致力于网咖行业软件开发及网咖运营管理的研究。
        </div>
        <div class="mt30">
          <el-button type="primary" class="btn" @click="goToDownload">立即下载</el-button>
        </div>
      </div>
    </div>
    <div class="pt90 pb50 bg7FA img">
      <div class="mark1">
        <el-image :src="require('../../static/image/home/barMark1.png')"></el-image>
      </div>
      <div class="mark2">
        <el-image :src="require('../../static/image/home/barMark2.png')"></el-image>
      </div>
      <div class="mb30 align-c">
        <div class="title">功能强大、满足网咖不同场景需求</div>
        <div class="subTitle">想客户之想，急客户所急</div>
      </div>
      <el-row :gutter="20">
        <el-col :span="6" :offset="6">
          <div
            class="rowFS whiteBg mb20"
            v-for="(item, index) in list1"
            :key="index"
          >
            <div>
              <el-image class="width60" :src="item.url"></el-image>
            </div>
            <div class="ml20">
              <div class="ftb mb5 f18">{{ item.title }}</div>
              <div class="dec1">
                {{ item.dec }}
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div
            class="rowFS whiteBg mb20"
            v-for="(item, index) in list2"
            :key="index"
          >
            <div>
              <el-image class="width60" :src="item.url"></el-image>
            </div>
            <div class="ml20">
              <div class="ftb mb5 f18">{{ item.title }}</div>
              <div class="dec1">
                {{ item.dec }}
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="img">
      <!-- <el-image
        :src="require('../../static/image/home/barBg3.png')"
      ></el-image> -->
      <el-image
        :src="url3"
      ></el-image>
      <div class="home3Title">
        <div class="mb30 align-c">
          <div class="title fwhite">移动智能办公</div>
          <div class="fwhite">
            便捷管理团队，提高办公效率
          </div>
        </div>
        <!-- <div class="rowFS">
          <div class="ml15 mr15" v-for="(item, index) in list3" :key="index">
            <el-image :src="item.url"></el-image>
            <div class="fwhite mt10">{{ item.title }}</div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="img">
      <!-- <el-image
        :src="require('../../static/image/home/barBg4.png')"
      ></el-image> -->
      <el-image
        :src="url4"
      ></el-image>
      <div class="home3Title">
        <div class="mb30 align-c">
          <div class="title">全新网咖会员端小程序</div>
          <div class="subTitle">
            全新UI界面，全面丰富的展示品牌形象，产品形象，助力加盟及销售
          </div>
        </div>
      </div>
    </div>
    <div class="img">
      <!-- <el-image
        :src="require('../../static/image/home/barBg5.png')"
      ></el-image> -->
      <el-image
        :src="url5"
      ></el-image>
      <div class="home3Title">
        <div class="mb30 align-c">
          <div class="title fwhite">效率不可或缺</div>
          <div class="color2AF">
            非常容易上手，网咖常用功能一应俱全，感受快捷舒适的工作体验
          </div>
        </div>
      </div>
    </div>
    <div class="img">
      <el-image
        :src="require('../../static/image/home/barBg6.png')"
      ></el-image>
      <div class="home3Title">
        <div class="mb30 align-c">
          <div class="title">电子上网价目表模板皮肤</div>
          <div class="subTitle">
            门店上网价目表、区域临时用户、会员价格显示、剩余机器数显示、客户直观了解
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../components/footer'
export default {
  components: { Footer },
  data() {
    return {
      list1: [
        {
          url: require('../../static/image/home/barIcon1.png'),
          title: '通用功能',
          dec: '呼叫服务、商品点购、电脑锁屏、消息中心、电脑防盗、智能支付、交班管理、数据汇总',
        },
        {
          url: require('../../static/image/home/barIcon2.png'),
          title: '门店管理',
          dec: '人员管理、职位权限、门店资产、门店公告、门店方案、提现账户、语音管理、短信管理',
        },
        {
          url: require('../../static/image/home/barIcon3.png'),
          title: '办公管理',
          dec: '任务管理、员工排班、考勤管理、交班管理、报销管理、收入管理、奖罚管理、便签管理、顾客呼叫、投诉建议',
        },
        {
          url: require('../../static/image/home/barIcon4.png'),
          title: '营销管理',
          dec: '门店活动、奖品管理、储值活动、入住活动、报名活动、订单活动、注册会员活动、员工好评活动、老带新活动、内部邀请活动、签到活动、短信营销、批量发券、动态房价活动、积分商城、活动宣传',
        },
        {
          url: require('../../static/image/home/barIcon5.png'),
          title: '财务管理',
          dec: '在线支付、绩效管理、资金流水、资金管理、薪资明细、股东分红、自定义收款账户、余额提现',
        },
      ],
      list2: [
        {
          url: require('../../static/image/home/barIcon6.png'),
          title: '商超管理',
          dec: '商品管理、仓库进货、吧台提货、商品配送、小程序点购、客户端点购、桌面扫码点购',
        },
        {
          url: require('../../static/image/home/barIcon7.png'),
          title: '仓库管理',
          dec: '出库管理、商品调拨、跨店调拨、库存盘点、供应商管理、多仓库管理',
        },
        {
          url: require('../../static/image/home/barIcon8.png'),
          title: '会员管理',
          dec: '卡券管理、积分商城、网费漫游、余额通用、会员储值、充值赠送、优惠活动、会员权益',
        },
        {
          url: require('../../static/image/home/barIcon9.png'),
          title: '报表管理',
          dec: '活动报表、考勤汇总、任务汇总、数据分析、营业报表、房间销售报表、商品销售报表、房间打扫报表、房间易耗品报表、商品进货报表、员工业绩报表、会员卡统计报表、会员漫游统计表、到店客人报表',
        },
        {
          url: require('../../static/image/home/barIcon10.png'),
          title: '小程序',
          dec: '移动便捷办公、快捷查看数据、员工打卡、会员中心、积分中心、活动中心、网咖会员端小程序、网卡管理端小程序',
        },
      ],
      list3: [
        {
          url: require('../../static/image/home/home4Icon1.png'),
          title: '网费充值',
        },
        {
          url: require('../../static/image/home/home4Icon1.png'),
          title: '商品点购',
        },
        {
          url: require('../../static/image/home/home4Icon1.png'),
          title: '点餐服务',
        },
        {
          url: require('../../static/image/home/home4Icon1.png'),
          title: '呼叫网管',
        },
        {
          url: require('../../static/image/home/home4Icon1.png'),
          title: '消息通知',
        },
        {
          url: require('../../static/image/home/home4Icon1.png'),
          title: '挂机锁',
        },
        {
          url: require('../../static/image/home/home4Icon1.png'),
          title: '积分中心',
        },
        {
          url: require('../../static/image/home/home4Icon1.png'),
          title: '活动中心',
        },
        {
          url: require('../../static/image/home/home4Icon1.png'),
          title: '余额充值',
        },
      ],
      screenWidth: 0,
      decWhite: 0,
      url3: 'https://wanzhuhu-1305683220.cos.ap-shanghai.myqcloud.com/website/barBg3.png',
      url4: 'https://wanzhuhu-1305683220.cos.ap-shanghai.myqcloud.com/website/barBg4.png',
      url5: 'https://wanzhuhu-1305683220.cos.ap-shanghai.myqcloud.com/website/barBg5.png',
    }
  },
  mounted() {
    // 首次加载时,初始化宽度
    // this.screenWidth = window.innerWidth
    this.screenWidth = document.documentElement.clientWidth
    this.decWhite = (460 / 1920) * this.screenWidth
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth
      this.decWhite = (460 / 1920) * this.screenWidth
    }
    console.log('decWhite', this.decWhite, 'screenWidth', this.screenWidth)
  },
  methods: {
    goToDownload() {
      this.$router.push({
        path: '/download',
        query:{id:4 },
        // name: 'download',
        // params:{id:4 },
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.img {
  position: relative;

  .el-image {
    display: flex;
  }
  .home1Title {
    position: absolute;
    top: 35%;
    left: 20%;
  }
  .home3Title {
    position: absolute;
    top: 16%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .mark1 {
    position: absolute;
    top: 10%;
    left: 20%;
  }
  .mark2 {
    position: absolute;
    bottom: 1%;
    right: 20%;
  }
}
.dec {
  // width: 450px;
  line-height: 30px;
  font-size: 16px;
}
.btn {
  padding: 10px 42px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
}
.whiteBg {
  height: 100px;
  padding: 20px 45px 20px 25px;
  // padding: 20px 45px;
  border-radius: 10px;
  // background: #ccc;
  background: #fff;
}
.width60 {
  width: 60px;
}
.dec1 {
  font-size: 13px;
  color: #7D818A;
  line-height: 24px;
}
</style>
