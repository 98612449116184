<template>
  <div class="content">
    <div class="content-title">{{ contentObj.title }}</div>
    <div class="subTitle f13">
      {{ contentObj.sbuTitle1 }}
      <div>
        {{ contentObj.sbuTitle2 }}
      </div>
    </div>
    <div class="mb30">
      <el-image
        :src="contentObj.url"
      ></el-image>
    </div>
    <el-row :gutter="10">
      <el-col :span="3" class="tag" v-for="(item, index) in contentObj.list" :key="index">
        {{ item }}
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    activedIndex: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tagList1: [
        '实施房态',
        '远期房态',
        '开房',
        '预定',
        '联房',
        '续房',
        '换房',
        '预留',
        '置净',
        '置脏',
        '打扫',
        '查房',
        '停车券',
        '早餐券',
        '发票',
        '冲调',
        '反结',
        '制卡',
        '添加预付款',
        '增加房间消费',
        '租赁',
        '损坏赔偿',
        '客史记录',
        '上下机记录',
        '增加入住人',
        '添加重要事项',
        '批量退房',
        '客房点单',
        '交班管理',
        '报表管理',
        '会员管理',
        '会员管理',
        '商品超市',
        '积分商城',
        '卡券管理'
      ],
      tagList2: [
        '显卡检测',
        '内存检测',
        '主板检测',
        '硬盘检测',
        'CPU检测',
        '消息提醒',
        '结账关机',
        'MAC地址检测',
        '机器号检测',
        'IP地址检测'
      ],
      tagList3: [
        '小程序检测',
        '商品点购',
        '自助续房',
        '会员充值',
        '房间账单',
        '游戏活动',
        '呼叫服务',
        '智能客控',
        '锁屏',
        '智能支付'
      ],
      tagList4: [
        'API对接',
        '远程客控',
        '门锁对接',
        '智能设备对接',
      ],
      tagList5: [
        '房价活动',
        '卡券管理',
        '批量发券',
        '充值送券',
        '入住送券',
        '新人特价',
        '新办公送券',
        '积分商城',
        '会员积分系统',
        '短信营销',
        '抽奖活动',
        '游戏活动',
        '签到活动',
        '订单活动',
        '老带新活动',
        '储值活动',
        '报名活动',
        '好评活动'
      ],
      tagList6: [
        '营业数据',
        '实时房态',
        '数据分析',
        '订单管理',
        '查房',
        '打扫',
        '余额漫游',
        '会员管理',
        '远期房态',
        '消息推送'
      ],
      tagList7: [
        '分类管理',
        '商品管理',
        '扫码点单',
        '仓库补货',
        '前台提货',
        '仓库补货记录',
        '库存盘盈盘亏',
        '客房补货记录',
        '前台超市',
        '积分商城',
        '客房点单'
      ],
      tagList8: [
        '会员充值绩效',
        '网费充值绩效',
        '商品销售绩效',
        '商品配送绩效',
        '员工好评绩效',
        '房扫业绩汇总',
      ],
      // tagList9: [''],
    }
  },
  computed:{
    contentObj () {
      let obj = {}
      if (this.activedIndex == 1) {
        obj.list = this.tagList1
        obj.title = '功能齐全，简单易用，上午学习，下午上岗，降低用人门槛'
        obj.sbuTitle1 = '传统前台收银系统上手难，使用成本高，玩住虎酒店前台收银系统界面直观易上手，业务流指引模式，'
        obj.sbuTitle3 = '“按部就班”操作不出错，降低用人门槛，避免“用工慌”。'
        obj.url = require('../../static/image/home/menuDetail1.png')
      } else if (this.activedIndex == 2){
        obj.list = this.tagList2
        obj.title = '硬件信息校验比对，有效防损防换防盗'
        obj.sbuTitle1 = '高频消费的电竞主机投资大，容易出现“损”、“换”、“盗”，玩住虎可自动识别初装时的电竞主机硬件信息，'
        obj.sbuTitle2 = '用户消费结账时，系统检测校验比对电脑硬件初装信息，止损于未然 。'
        obj.url = require('../../static/image/home/menuDetail2.png')
      } else if (this.activedIndex == 3) {
        obj.list = this.tagList3
        obj.title = '为用户提供足不出户的“吃住玩”一站式消费体验'
        obj.sbuTitle1 = '既能高效满足入住体验，便捷的客房服务更能为游戏爱好者提供足不出户的“吃住玩”一站式消费体验，'
        obj.sbuTitle2 = '查房核单更简单、消费更私密便捷、商品谁消费谁买单（也可卡扣），礼尚往来，促进消费。'
        obj.url = require('../../static/image/home/menuDetail3.png')
      } else if (this.activedIndex == 4) {
        obj.list = this.tagList4
        obj.title = '为用户提供前置式只能家居体验'
        obj.sbuTitle1 = '传统酒店需入住进房后才可操控智能家居，毫无体验感，玩住虎酒店系统可实现智能家居与计费的互通'
        obj.sbuTitle2 = '用户通过酒店小程序下单付费排序后，即可在线远程操控智能就家居，私项“家”一般的智能体验'
        obj.url = require('../../static/image/home/menuDetail4.png')
      } else if (this.activedIndex == 5) {
        obj.list = this.tagList5
        obj.title = '基于私域流量的精细化会员运营'
        obj.sbuTitle1 = '千人千面，千店千况，客户要吃什么？爱玩什么？爱住什么房型？玩住虎酒店系统基于私域流量及用户画像大数据，'
        obj.sbuTitle2 = '不仅有丰富易用的营销活动工具及会员成长体系，更有懂心的消费细节，协助电竞酒店闭环化实现“拉新、留存、挽流失”。'
        obj.url = require('../../static/image/home/menuDetail5.png')
      } else if (this.activedIndex == 6) {
        obj.list = this.tagList6
        obj.title = '收入明细一本账，轻松监管营收及运营情况'
        obj.sbuTitle1 = '移动办公5分钟，店内情况全掌握，玩住虎酒店系统可实现现金、三方支付、OTA、会员卡扣收入明细一本账，'
        obj.sbuTitle2 = '精确实用，收退款便捷，提升体验，减少工作量，避免监守自盗，清晰的数据分析让内部协同经营更高效。'
        obj.url = require('../../static/image/home/menuDetail6.png')
      } else if (this.activedIndex == 7) {
        obj.list = this.tagList7
        obj.title = '增强体验让商品多卖，提高效率让商品易管'
        obj.sbuTitle1 = '面向C端的用户，基于用户消费行为大数据，为用户提供“客户端、扫码点单、在线商城”的“在店及离店”便捷消费途径，让商品多卖。'
        obj.sbuTitle2 = '面向B端用户，提供简单易用的商品上下架、提货补货、库存盘点及微信查房，有效帮助员工提升效率，让商品多卖易管。'
        obj.url = require('../../static/image/home/menuDetail7.png')
      } else if (this.activedIndex == 8) {
        obj.list = this.tagList8
        obj.title = '提供基于数据为维度的绩效管理工具'
        obj.sbuTitle1 = '招人难？用人难？留人难？玩住虎酒店系统专注电竞酒店，提供基于数据为维度的绩效管理工具，'
        obj.sbuTitle2 = '可简单有效的量化员工工作价值，让员工的每一份努力都能被看到，每一份付出都有所收获。'
        obj.url = require('../../static/image/home/menuDetail8.png')
      } else if (this.activedIndex == 9) {
        // obj.list = this.tagList9
        obj.title = '用数据挖掘真相'
        obj.sbuTitle1 = '以客户为中心？你真的比你的竞争对手更懂你的客户吗？玩住虎酒店系统协助您清晰掌握客户的饮食喜好、'
        obj.sbuTitle2 = '游戏喜好、住宿喜好，透过数据挖掘商机真相，让营销活动更有凭有据。'
        obj.url = require('../../static/image/home/menuDetail9.png')
      }
      return obj
    }
  },
}
</script>

<style lang="scss" scoped>
.content {
  padding: 50px 45px;
  text-align: center;
}
.content-title {
  font-size: 24px;
  color: #121f27;
  line-height: 14px;
  font-weight: 800;
  margin-bottom: 20px;
}
.tag {
  background: #EBF0FF;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 13px;
  margin: 5px 5px;
  color: #2B2F36;
}
</style>
